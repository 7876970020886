import { Link } from "gatsby"
import * as React from "react"
import Layout from "./../components/layout"
import SEO from "../components/seo"
import ServicesCarousel from "../components/servicesCarousel"

const contactPage = () => {
  return (
    <Layout>
      <SEO title="Услуги"/>
      <div className='container'>
        <h1>Услуги</h1>
        <ServicesCarousel/>
        <br/>
        <Link to="/contact/"><button className="btn btn-lg btn-cobalt align-center fs-2 mb-5">Свържете се с нас сега</button></Link>
      </div>
    </Layout>)
}

export default contactPage